// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')
require('jquery')
require('jquery-ui')
require('@nathanvda/cocoon')

import '@hotwired/turbo-rails'

import Rails from '@rails/ujs'
window.Rails = Rails

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('bootstrap')

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

import appInit from 'angle/app.init.js'
document.addEventListener('DOMContentLoaded', appInit)

import tableSort from "table-sort-js/table-sort.js";

//import '../application/sentry'
import '../application/clickable-rows'
import '../application/protocol-form'
import '../application/time-ago'
import '../application/protocol-members'
import '../application/active-protocol-selector'
import '../application/filter-reset-button'
import '../application/marker-io'
import '../application/clear-field-link'
import '../application/bootstrap-datepicker'
import '../application/bootstrap-select'
import '../application/update-color-swatch'
import 'bootstrap-modal-js'
import '../application/notification-count'
import '../controllers'
import '../application/transfers-sync-retire-cages'
import '../application/status-bar'
import '../application/search-ui'
import '../application/textarea-print'
import '../application/success-messages'
import '../application/committee-meeting'
import '../application/import-form'
import '../application/transfer-form'
import '../application/wean-form'
import '../application/cage-form'
import '../application/nav-list-v2'
import '../application/strain-select'
import '../application/update-height-index'
import '../application/flatpickr'
