$(function () {
  $(".cage-form-protocol-id").on( "change", function() {
    const el = $(event.target)
    const url = new URL(window.location);
    if (el.val()) {
      url.searchParams.set("protocol_id", el.val());
    } else {
      url.searchParams.delete("protocol_id");
    }
    window.location = url;
  } );
})
