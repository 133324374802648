let new_cage_id = 1
function addNewCageWean(node, id) {
  const option = $('<option>', { value: id, text: `New Cage ${id}` })
  node.append(option)
}

function setupLineItemWean(line_item) {
  const new_cage_list = line_item.find('select.new-cage-list')
  for (let i = 1; i < new_cage_id; i++) {
    addNewCageWean(new_cage_list, i)
  }
}

function updateLineItem($this) {
  const $line_item = $this.closest('.wean-line-item')
  $line_item.find('.hidden-column').hide()
  const lineItemColumns = ".animal-description-column, \
                           .number-of-animals-column, \
                           .sex-column, \
                           .strain-column";
  switch ($this.val()) {
    case 'to_existing_cage':
      $line_item.find(".destination-cage-column, " + lineItemColumns).show()
      break
    case 'to_new_cage':
      $line_item.find(".new-destination-cage-column, " + lineItemColumns).show()
      break
  }
}

function resizeTextArea() {
  $(".wean_line_items_animal_description").find("textarea").each(function () {
    this.setAttribute("style", "height:" + (this.scrollHeight) + "px; overflow-y:hidden;");
  }).on("input", function () {
    $(this).css("height", "0px")
    $(this).css("height", this.scrollHeight + "px")
  });
}

function updateDestinationCage($this) {
  const $line_item = $this.closest(".wean-line-item");
  $line_item.find(".destination-cage-column input").val(null)
}

$(function () {
  $('#wean-line-items').on('cocoon:after-insert', function (e, line_item) {
    setupLineItemWean(line_item)
    updateLineItem(line_item.find('select.wean-line-item-action'))
    resizeTextArea()
  })
  $('#wean-line-items .wean-line-item').each(function () {
    setupLineItemWean($(this))
    updateLineItem($(this).find('select.wean-line-item-action'))
    resizeTextArea()
  })

  $(document).on('click', '.wean.generate-cage', function (e) {
    addNewCageWean($('select.new-cage-list'), new_cage_id)
    $(this).closest('.wean-line-item').find('select.new-cage-list').val(new_cage_id)
    new_cage_id += 1
    e.preventDefault()
  })

  $(document).on('change', 'select.wean-line-item-action', function () {
    updateLineItem($(this))
    updateDestinationCage($(this))
    resizeTextArea()
  })
})
