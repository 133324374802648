import React, { useState, useRef, useEffect, useReducer, Reducer, useCallback } from 'react'
import QrScanner from 'qr-scanner'
import './room_audit/styles.css'
import { beep } from './room_audit/beep'

function Instructions({ message, latest, count }) {
  return (
    <>
      <h3>{message}</h3>
      <h4>{latest}</h4>
      <h5>{count} Scanned</h5>
    </>
  )
}

function QRScanner({ onScan }) {
  const ref = useRef()
  const [scanner, setScanner] = useState<QrScanner>()
  const [scanned, setScanned] = useState(null)

  useEffect(() => {
    if (scanned) {
      onScan(scanned)
    }
  }, [scanned])

  useEffect(() => {
    const s = new QrScanner(ref.current, (result) => setScanned(result), {
      // onDecodeError: (e) => console.warn(e),
      highlightScanRegion: true,
      highlightCodeOutline: true,
      returnDetailedScanResult: true
    })
    setScanner(s)
    s.start()

    return function () {
      s.stop()
      s.destroy()
    }
  }, [])
  return <video className='qr-scanner-video' ref={ref}></video>
}

async function reportCageCard(reportURL, cageCardId) {
  const response = await fetch(reportURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ cage_card_id: cageCardId })
  })
  if (!response.ok) {
    return { ok: false }
  } else {
    const data = await response.json()
    return { ok: true, already_exists: data.already_exists }
  }
}

interface ScanTrackerState {
  scanned: Set<number>
  last: number | null
}

function useCageCardScanTracker() {
  const [state, setState] = useReducer<Reducer<ScanTrackerState, Partial<ScanTrackerState>>>(
    (currentState, newState) => ({ ...currentState, ...newState }),
    { scanned: new Set<number>(), last: null }
  )

  function scannedLast(cageCardId) {
    return cageCardId === state.last
  }

  function scannedBefore(cageCardId) {
    return state.scanned.has(cageCardId)
  }

  function noteScanned(cageCardId) {
    setState({ scanned: new Set(state.scanned).add(cageCardId), last: cageCardId })
  }

  return {
    scannedLast,
    scannedBefore,
    noteScanned,
    scanned: state.scanned
  }
}

function cageCardIdFromQRCode(result) {
  const urlString = result.data.startsWith('//')
    ? `https:${result.data}`
    : result.data.startsWith('https://')
    ? result.data
    : `https://${result.data}`
  const url = new URL(urlString)
  const parts = url.pathname.split('/')
  if (parts[0] === '' && parts[1] === 'cage_cards' && parts[3] === 'scan') {
    return parseInt(parts[2])
  }
  if (parts[0] === '' && parts[1] === 'c') {
    return parseInt(parts[2])
  }
}

function ScannedList({ scanned }) {
  const scannedCageCardNumbers = Array.from(scanned.values())
    .map((n) => '#' + n)
    .join(', ')
  return <div>Scanned cage cards: {scannedCageCardNumbers}</div>
}

export default function RoomAuditScan(props) {
  const { scannedLast, scannedBefore, noteScanned, scanned } = useCageCardScanTracker()

  const [instructions, setInstructions] = useState('Point the camera at a cage card QR code')
  const [latest, setLatest] = useState('')

  function onNewScan(cageCardId) {
    beep('new')
    setLatest(`Already Scanned Cage Card #${cageCardId}`)
  }

  function onExistingScan(cageCardId) {
    beep('existing')
    setLatest(`Already Scanned Cage Card #${cageCardId}`)
  }

  function onScanError(cageCardId) {
    beep('error')
    setLatest(`Error Scanning Cage Card #${cageCardId}`)
  }

  async function onScan(result) {
    const cageCardId = cageCardIdFromQRCode(result)
    if (cageCardId) {
      if (scannedLast(cageCardId)) {
        // no-op
      } else if (scannedBefore(cageCardId)) {
        noteScanned(cageCardId)
        onNewScan(cageCardId)
      } else {
        noteScanned(cageCardId)
        const response = await reportCageCard(props.report_url, cageCardId)
        if (response.ok && !response.already_exists) {
          onNewScan(cageCardId)
        } else if (response.ok) {
          onExistingScan(cageCardId)
        } else {
          onScanError(cageCardId)
        }
      }
    } else if (result.data) {
      setLatest('Not a recognized cage card QR code')
    }
  }

  return (
    <>
      <Instructions message={instructions} latest={latest} count={scanned.size} />
      <QRScanner onScan={onScan} />
      <ScannedList scanned={scanned} />
    </>
  )
}
