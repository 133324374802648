import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import $ from 'jquery';
import 'select2'
import * as jQuery from 'jquery';

export default class extends Controller {
  connect() {
    $("select.select2-animal-order").select2({
      theme: 'bootstrap',
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
    })
      .on('select2:open', function (e) {
        const select2 = $(e.target).data('select2');
        if (!select2.options.get('multiple')) {
          select2.dropdown.$search.get(0).focus();
        }
      })
      .off('select2:select')
      .on('select2:select', function (event) {
        event.preventDefault()
        const el = $(event.target);
        const form = el.closest("form")
        const animalOrder = form[0].dataset.animalOrder
        const column = event.target.id.replace("animal_order_", "").replace("_true", "").replace("_false", "")
        if (["textarea", "text", "number"].includes(event.target.type)) {
          const animalOrderData = JSON.parse(animalOrder)
          animalOrderData[column] = event.target.value
          form[0].dataset.animalOrder = JSON.stringify(animalOrderData)
          return
        }
        const request = new FetchRequest("get", `/animal_orders/render_form?column=${column}&value=${event.target.value}&animal_order=${animalOrder}`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          },
          responseKind: 'turbo-stream'
        });
        request.perform();
        sessionStorage.focusElement = event.target.id
      })
    $(`#${sessionStorage.focusElement}`).focus()
    sessionStorage.removeItem('focusElement')

    $('.select2-selection').keydown((ev) => {
      if (ev.which < 32) return;
      var target = jQuery(ev.target).closest('.select2-container');
      if (!target.length) return;
      target = target.prev().is('div') ? target.prev().find('select') :target.prev();
      target.select2('open');
      var search = target.data('select2').dropdown.$search || target.data('select2').selection.$search;
      search.focus();
    });
  }

  templateResult(el) {
    const $result = $(
      `<div class='select2-option'>${el.text}</div>`
    );
    return $result;
  }

  templateSelection(el) {
    const $result = $(
      `<div class='select2-option selection'>${el.text}</div>`
    );
    return $result;
  }

  render_form(event) {
    event.preventDefault()
    const el = $(event.target);
    const form = el.closest("form")
    const animalOrder = form[0].dataset.animalOrder
    const column = event.target.id.replace("animal_order_", "").replace("_true", "").replace("_false", "")
    if (["textarea", "text", "number", "checkbox"].includes(event.target.type)) {
      const animalOrderData = JSON.parse(animalOrder)
      animalOrderData[column] = event.target.type == "checkbox" ? event.target.checked : event.target.value
      form[0].dataset.animalOrder = JSON.stringify(animalOrderData)
      return
    }
    const request = new FetchRequest("get", `/animal_orders/render_form?column=${column}&value=${event.target.value}&animal_order=${animalOrder}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      responseKind: 'turbo-stream'
    });
    request.perform();
    sessionStorage.focusElement = event.target.id
  }
}
